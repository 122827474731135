<template>
  <div :class="!light ? 'secondary' : 'grey lighten-2'" class="pa-8">
    <h3 :class="{'white--text': !light, 'text-h4': $vuetify.breakpoint.mobile, 'text-h3': !$vuetify.breakpoint.mobile}"
        class="text-uppercase mb-8 font-weight-bold text-break text-center">
      Why Get A Subscription?
    </h3>
    <v-row>
      <v-col v-for="benefit in benefits" :key="benefit.title" cols="12" lg="4" md="4">
        <h5 :class="!light ? 'white--text' : ''" class="text-h5 text-center font-weight-bold mb-4">
          {{ benefit.title }}
        </h5>
        <v-img
            :aspect-ratio="1"
            :height="$vuetify.breakpoint.mobile ? '170px' : '300px'"
            :src="!light ? benefit.imageW : benefit.imageB"
            contain
        ></v-img>
      </v-col>
    </v-row>
    <v-container v-if="extended" class="mt-6">
      <p :class="!light ? 'white--text' : ''" class="text-center">
        A Subscription allows you to list parts and keep all parts listed until you sell them.
      </p>
      <p :class="!light ? 'white--text' : ''" class="text-center">
        Providing you stay subscribed; your parts will stay listed for years; saving the need to re-list.
      </p>
      <p :class="!light ? 'white--text' : ''" class="text-center">
        This is handy for some of the more unique parts, as they may take longer to sell. As we only charge for a
        Subscription, not a commission on parts you can list smaller parts without costing you more than the part is
        worth, once you list a part, you can sell as many of this part as you like, without paying extra.
      </p>
    </v-container>
    <v-container v-if="viewPlans">
      <v-btn :to="{name: 'Subscriptions'}" block class="pa-8 mt-6" color="primary">View Plans</v-btn>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "WhySubscription",
  props: {
    extended: {
      default: true,
    },
    light: {
      default: false
    },
    viewPlans: {
      default: false
    }
  },
  data() {
    return {
      benefits: [
        {
          title: "For Australians",
          imageW: require("@/assets/aus_icon.png"),
          imageB: require("@/assets/aus_icond.png")
        },
        {
          title: "Expand your audience",
          imageW: require("@/assets/audience_icon.png"),
          imageB: require("@/assets/audience_icond.png")
        },
        {
          title: "Include your whole catalog",
          imageW: require("@/assets/catalog_icon.png"),
          imageB: require("@/assets/catalog_icond.png")
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>