<template>
  <div>
    <div>
      <v-row class="secondary">
        <v-col cols="12" lg="4" md="4">
          <v-carousel cycle height="400" hide-delimiter-background>
            <v-carousel-item src="@/assets/ken.jpg"/>
            <v-carousel-item src="@/assets/ken2.jpg"/>
            <v-carousel-item src="@/assets/ken3.jpg"/>
            <v-carousel-item src="@/assets/ken4.jpg"/>
          </v-carousel>
        </v-col>
        <v-col class="pa-8" cols="8">
          <h3 class="text-h3 text-uppercase font-weight-bold white--text mb-4">
            Ken's Story
          </h3>
          <p class="text-body-1 white--text">
            As a Panel Beater for 30 years l have accumulated a variety of parts - from genuine parts that have been
            ordered in and not used for various reasons; to cars that I have brought for selective parts and have
            retained the left overs.
          </p>
          <p class="text-body-1 white--text">
            So, I assume many other Tradies have done the same; but without this type of site, I am unable to know and
            access what they have.
          </p>
          <p class="text-body-1 white--text">
            Some of these parts are unique and hard to obtain. You can usually only find out by knowing and talking to
            the right person.
          </p>
          <p class="text-body-1 white--text">
            My hope is that this site will help connect the parts to the places they belong!
          </p>
        </v-col>
      </v-row>
      <v-row class="grey lighten-2">
        <v-col class="pa-8" cols="12" lg="7" md="7">
          <h3 class="text-h3 text-uppercase font-weight-bold mb-4">
            Who are we?
          </h3>
          <p class="text-body-1">
            Most of all, we are Australian Owned and Operated.
          </p>
          <p class="text-body-1">
            This is a site custom built for the needs of Tradies, Car and Motorsport enthusiasts alike.
          </p>
          <p class="text-body-1">
            We have a passion for Vintage and Classic cars and we know how hard it is to obtain some of the parts needed
            for restoration.
          </p>
          <p class="text-body-1">
            We know how hard it is to obtain some of the parts needed for restoration.
          </p>
          <p class="text-body-1">
            Most sites have the larger items, but this site, with its unique Subscription System, allows users to list
            the smallest of parts, and stay on the site until someone needs them, without getting buried by other
            listings.
          </p>
          <p class="text-body-1">
            We also have a passion for Motorsport in all forms. We hope that this site will help - if you are at a track
            for a Multi Day Event and need parts, we have included Part Location, Town & Postcode. So, the people
            listing a Motorsport Part may even be able to help you out on the weekend. Also, this allows you to pick up
            parts close to the tracks you are visiting, whether Interstate or Local.
          </p>
        </v-col>
        <v-col class="pa-0">
          <img alt="Who we are" height="100%" src="@/assets/yard.jpg" width="100%"/>
        </v-col>
      </v-row>
      <v-row class="secondary">
        <v-col class="pa-8" cols="12" lg="6" md="6">
          <h3 class="text-h3 text-uppercase font-weight-bold mb-4 white--text">
            What we do?
          </h3>
          <p class="text-body-1 white--text">
            Sell My Car Parts is here to bring together those who are looking to
            buy and sell parts to a single point. We've found that sites like
            Gumtree, Facebook Marketplace and other listing sites don't meet the
            needs of parts sellers. Items can be difficult to find, and are
            quickly buried under hundreds of posts. Sell My Car Parts lets
            sellers list items and not have to worry about relisting or updating
            them every few days.
          </p>
        </v-col>
        <v-col class="pa-8" cols="12" lg="5" md="6">
          <h3 class="text-h3 text-uppercase font-weight-bold mb-4 white--text">
            Why we do it?
          </h3>
          <p class="text-body-1 white--text">
            This is a place to connect buyers and sellers; a specialised
            marketplace to meet their needs, with an added level of security to
            make sure you're getting a fair deal.
          </p>
        </v-col>
      </v-row>
      <v-row class="secondary">
        <v-col class="pa-8" cols="12" lg="6" md="6">
          <h3 class="text-h3 text-uppercase font-weight-bold mb-4 white--text">
            Facebook
          </h3>
          <p class="text-body-1 white--text">
            To see the latest in SellMyCarParts, We have a Facebook page where we post updates and news about the
            site. If you have any questions, need support or want to get in touch, you can message us there.
          </p>
          <v-btn color="primary" @click="openFacebook">Click Me</v-btn>
        </v-col>
        <v-col class="pa-8" cols="12" lg="5" md="6">
          <h3 class="text-h3 text-uppercase font-weight-bold mb-4 white--text">
            Acknowledgments
          </h3>
          <p class="text-body-1 white--text">
            Assets used within this website might not be entirely created by SellMyCarParts.com.au. Please find our
            acknowledgements to talented creators by clicking the button below.
          </p>
          <v-btn :to="{name: 'Acknowledgments'}" color="primary">See Acknowledgments</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-8" cols="12">
          <h3 class="text-h3 text-uppercase font-weight-bold mb-4">
            What do we offer?
          </h3>
          <p class="text-body-1">
            We offer an opportunity to establish your own storefront to sell
            your spare parts, without needing to go through all the effort of
            creating and promoting your own site. Let us handle the technical
            part, so that you can get to buying and selling!
          </p>
        </v-col>
      </v-row>
      <WhySubscription :extended="false" view-plans="true"/>
    </div>
  </div>
</template>

<script>
import WhySubscription from "@/components/Subscriptions/WhySubscription";

export default {
  components: {WhySubscription},
  metaInfo: {
    title: "About Us",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
  methods: {
    openFacebook() {
      window.open("https://www.facebook.com/profile.php?id=100086533260281&mibextid=ZbWKwL", "_blank");
    },
  },
};
</script>
